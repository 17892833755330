.container-html-content {
  margin: 1rem 2rem;
  //
  //p {
  //  margin: .5rem
  //}
  //
  //.ql-size-large {
  //  font-size: 20px
  //}
  //
  //.ql-indent-1 {
  //  margin-left: 1rem
  //}
  //.ql-indent-2 {
  //  margin-left: 2rem
  //}
  //.ql-indent-3 {
  //  margin-left: 3rem
  //}
  //.ql-indent-4 {
  //  margin-left: 4rem
  //}
  //.ql-indent-5 {
  //  margin-left: 5rem
  //}
  //.ql-indent-6 {
  //  margin-left: 6rem
  //}
  //.ql-indent-7 {
  //  margin-left: 7rem
  //}
  //.ql-indent-8 {
  //  margin-left: 8rem
  //}
}
