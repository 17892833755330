@import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0-rc2/css/bootstrap-glyphicons.css");	
.homeSite {
  background-color: $backgroundColor;
  .background-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    text-align: center;
    height: calc(100% - 180px - 10px);
    min-height: 37rem;
    width: 100%;
    max-width: 1440px;
    .background-image {
      width: 100%;
      height: 100%;
      background: no-repeat center;
      background-size: cover;
    }
  }
  .body-site {
    position: relative;
    flex-basis: 65%;
    min-height: 37rem;
    .body-content {
      z-index: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      min-height: 45rem;
      justify-content: flex-end;
      .quote-form {
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;        
        width: 360px;
        background: #FFF;
        padding: 0 20px;
        margin: 7rem 20px 10px 0;
        border-top: .5px solid #FFF;
        min-height: 400px;

        .layer-quote-form {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(255, 255, 255, .6);
        }

        .titles {
          z-index: 1;
          width: 100%;
          margin-top: -52px;
          .title-quote {
            font-size: 20px;
            font-weight: 400;
            text-transform: uppercase;
            line-height: 25px;
          }
          .title-quote-2 {
            font-size: 30px;
            line-height: 30px;
            text-transform: uppercase;
            font-weight: 600;
            @media screen and (max-width: 77rem) {
              font-size: 24px;
              line-height: 24px;
            }
          }
        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          @include rectangle(#FFF, calc(100% - 70px), 70px);
          transform: translateY(-100%);
        }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          @include arrow(bottomLeft, #FFF, 36px);
          transform: translateY(-100%);
        }
      }
      &-top {
        justify-content: flex-start;
      }
    }
  }
}

.flex-display {
  display: flex;
  flex-shrink: 0;
  min-height: fit-content;
  &.full-start {
    align-items: flex-start;
    justify-content: center;
  }
  &--home {
    height: 100%;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: auto;
  margin-left: -20px;
  .title {
    width: 100%;
    display: flex;
    color: #fff;
    font-size: 5rem;
    font-weight: 200;
    margin-right: 3.5rem;
    flex-grow: 1;
    &--top {
      justify-content: center;
      align-items: flex-start;
    }
    &--bottom {
      justify-content: center;
      align-items: flex-end;
    }
    &--left {
      justify-content: flex-start;
      align-items: center;
    }
    &--right {
      justify-content: flex-end;
      align-items: center;
    }
    &--center {
      justify-content: center;
      align-items: center;
    }
  }
  .subTitle {
    margin-left: 1rem;
    font-size: 30px;
    color: $blueAllianz;
  }
}

.tiles-container {
  box-sizing: border-box;
  width: auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 10px;
  flex-shrink: 0;
}

.tile {
  box-sizing: border-box;
  height: 240px;
  flex-basis: 32%;
  background-color: #fff;
  margin: 10px 0 0 .5%;
  overflow: auto;
  .tile-title {
    height: 25%;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    padding: 1rem;
    background-color: $blueAllianz;
    color: white;
  }
  .tile-content {
    padding: 1rem;
    font-weight: 400;
    color: #54585a;
    .rich-text-content p {
      color: black;
      margin: 0;
    }
  }
}

.copyright {
  margin-top: 1.5rem;
  width: 80%;
  margin-bottom: 20px;
  text-align: center;
}
@media all and (-ms-high-contrast: none) and (max-width: 77rem), (-ms-high-contrast: active) and (max-width: 77rem){
  .tiles-container {
    height: 100%;
  }
}
@media all and (-ms-high-contrast: none) and (max-width: 47rem), (-ms-high-contrast: active) and (max-width: 47rem){
  .tiles-container {
    height: 37rem;
  }
}
@media screen and (max-width: 77rem) {
  .homeSite {
    min-height: 600px;
    & > .body-site > .background-container {
      height: 100%;
    }
  }
  .steps {
    height: auto;
    .title {
      margin-right: 0;
      padding-top: 1rem;
    }
  }
  .tiles-container {
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
    flex-direction: column;
    flex-wrap: nowrap;


    .tile {
      //height: 10rem;
      width: 100%;
      height:140px;
    }
  }

  .flex-display {
    &.flex-display.full-start {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-evenly;
    }
  }

}

@media screen and (max-width: 47rem) {
  .homeSite > .body-site {
    flex-basis: 90%;
    .body-content {
      height: auto;
    }
  }
  .background-container {
    height: 100%;
  }
  .flex-display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  .tiles-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    .tile {
      margin: 0 0 10px 0;
    }
  }
  .homeSite .body-site .body-content .quote-form {
    margin-right: 0;
  }
  .steps {
    margin-left: 0;
  }
}

.error-purchase {
  margin-top: 2rem;
  font-family: "Gotham Medium", Arial, serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #E74C3C;
  border-radius: 3px;
  color: #E74C3C;
  background: #FADBD8;
  padding: 1rem;
}

.error-no-dcx {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(52, 73, 94, .6);
  z-index: 100;
  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFF;
    font-family: "Open Sans", Arial, serif;
    color: #0C416E;
    padding: 4rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 101;
  }
}
.dockContent {
  color: white;
  background-color: #B71E3F;  
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  text-align: center;

  .notify-txt {    
    text-align: right;
    width: 100%;
  }

  .close {
    color: #fff; 
    opacity: 1;
    float: right;
    position: absolute !important;
    z-index: 1!important;
    right: 10px !important;
    top: 10px !important;
    cursor: pointer;   
    :hover {
      color: #fff;
    }     
  }		
  .glyphicon-info-sign {
    font-size: 35px;
  }
  a {
    color: #fff; 
    font-size: 24px;
    text-decoration: none;	
    line-height: 45px;
  }
}	

@media screen and (max-width: 26rem) {
  .dockContent {
    h1 {
      font-size: 2rem;
    }
    a {
      color: #fff; 
      font-size: 1.5rem;
      text-decoration: none;	
      line-height: 45px;
    }  
  }
  .steps {
    .title {
      font-size: 2rem;
    }
    .subTitle {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 414px) {
  .dockContent {
    .notify-txt {    
      text-align: center;
      width: 100%;
    } 
  }
}
