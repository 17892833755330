.container-switches {
  width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.switch-language {
  position: relative;
  margin-left: 20px;
  display: flex;
  align-items: center;
  .label {
    cursor: pointer;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    &::after {
      top: 50%;
      left: calc(100% + 3px);
      transform: translateY(-50%);
      position: absolute;
      content: '\25BC';
      font-size: 10px;
      color: gray;
    }
  }

  .flag {
    position: relative;
    margin-left: 1rem;
    cursor: pointer;
  }

  .container-list {
    border-top: 1px solid lightgray;
    width: 140px;
    position: absolute;
    top: 100%;
    height: auto;
    background: white;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    z-index: 100;

    &--open {
      display: block;
    }

    & > a {
      height: 3rem;
      display: flex;
      align-items: center;
      padding: 0 .5rem;
      box-sizing: border-box;

      img {
        margin-right: 1rem;
        height: 32px;
      }

      & + a {
        border-top: 1px solid lightgray;
      }
    }
  }
}
@media screen and (max-width: 1300px){
  .container-switches {
    width: 100%;
  }
}
@media screen and (max-width: 1300px){
  .container-switches {
    width: 100%;
  }
  .switch-language {
    margin-left: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;

    .label {
      padding-left: 5px;
      padding-right: 5px;
    }

    .flag {
      position: initial;
      cursor: pointer;
    }

    .container-list {
      width: 140px;
      position: absolute;
      bottom: 100%;
      height: auto;
      background: white;
      left: 50%;
      transform: translateX(-50%);
      &--open {
        display: block;
      }

      a{
        background: white;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .container-switches {
    width: 100%;
    flex-wrap: wrap;
  }
  .switch-language {
    display: flex;
    align-items: center;
    position: relative;

    .label {
      padding-left: 5px;
      padding-right: 5px;
    }

    .flag {
      position: initial;
      cursor: pointer;
    }

    .container-list {
      width: 140px;
      position: absolute;
      bottom: 100%;
      height: auto;
      background: white;
      left: 0;
      transform: translate(0);
      &--open {
        display: block;
      }

      a{
        background: white;
      }
    }
  }
}
