html {
  font-size: $base-font-size;
  font-family: 'Open Sans', Arial, sans-serif;
}
body{
  background-color: #eceded;
  font-family: 'Open Sans', Arial, sans-serif;
}

a,
button {
  cursor: pointer;
}

.rich-text-content {
  box-sizing: border-box;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.msg-info {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-weight: bold;
  font-size: 1.2rem;
}

select:invalid { color: lightgray; }
select:disabled {
  font-style: normal;
  background: #d7d7d7;
}
option { color: black; }
option:disabled { background: lightgray; }
select {
  background: #FFF;
}

option.placeholder-option {
  color: #666;
}

.skip-link {
  position: absolute;
  top: -40px;
  left: 0;
  background: #000;
  color: #fff;
  padding: 8px;
  z-index: 100;
  text-decoration: none;
  transition: top 0.3s;
}

.skip-link:focus {
  top: 0;
}

.ql-editor {
  a {
    color: #124ba1;
    text-decoration: underline;
  }
}
