.cookie-banner {
  & > * {
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 14.6667px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 50px;
  background: rgba(255,255,255,0.85);
  .content {
    padding-left: 4rem;
    p {
      padding: 0;
      margin: 0;
      display: inline;
    }
    a {
      margin-left: .5rem;
      text-decoration: underline;
      color: rgb(0, 169, 224);
      display: inline;
      &:hover {
        color: #FA781E;
      }
    }
  }
  .container-actions {
    display: flex;
    align-items: center;
    button {
      margin: 0 1rem;
    }
    .accept {
      background: #0DB039;
      outline: none;
      border: none;
      color: white;
      padding: 8px 18px;
      font-size: 13px;
      font-family: Arial, sans-serif;
      font-weight: 700;
      min-width: 180px;
      &::before {
        content: '\2713';
        padding-right: 3px;
      }
    }
    .close-banner {
      background: #dcdcdc;
      border: 2px solid white;
      border-radius: 50%;
      width: 1.7rem;
      height: 1.7rem;
      position: relative;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #5a5a5a;
        transform: translate(-50%, -50%);
        content: '\2716';
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cookie-banner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .content {
      padding-left: .3rem;
      padding-top: 1rem;
    }
    .container-actions {
      margin: 1rem 0;
      align-self: flex-end;
    }
  }
}
