.confirmation-page {
  display: flex;
  flex-direction: column;
  font-family: "Gotham Medium", Arial, serif;
  &:focus {
    outline: none;
  }
  .confirmation-block + .confirmation-block {
    display: flex;
    padding: 1.5rem;
    border-top: 1px solid #CCC;
    &:last-child {
      border: none;
    }
    .recommendation {
      cursor: pointer;
      flex-basis: 85%;
      padding: 0 1rem 0 0;
      box-sizing: border-box;      
    }
    .button {
      flex-basis: 17%;
      @include wlf-button-style("next");

      &:hover {
        color: white;
        text-decoration: none;
      }
      &:focus {
        outline: 2px solid #000000;
        outline-offset: 2px;
        background-color: #e7f1ff;
        text-decoration: none;
      }
    }
  }  
  .header {
    &:focus {
      outline: none; // Remove default outline
    }
  
    // Add custom focus styles for keyboard users
    &.keyboard-focus {
      outline: 2px solid #000; // Add a custom outline color for focus state
      outline-offset: 2px; // Optional, for better visibility
    }
  }
  .thanks {
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    color: #006272;
  }
  .policy {
    color: #545555;
    justify-content: flex-start;
    align-items: center;
    .icon {
      font-size: 3.5rem;
    }
    .policy-number {
      margin-left: .5rem;
      .number {
        color: #545555;
        font-size: 2rem;
      }
    }
  }
  .message {
    font-family: "Gotham Light", Arial, serif;
    align-items: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .terminate {
    align-items: center;
    color: #006272;
    font-family: "Gotham Light", Arial, serif;
  }
}
