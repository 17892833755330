.multi-select-passenger {
  position: relative;
  background: white;
  z-index: 1;
  border: 1px solid #CFCFCF;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  height: 2.5rem;

  .green {
    color: #155724;
  }
  .red {
    color: #721c24;
  }

  .input-view {
    width: 100%;
  }

  .result-phrase {
    width: 100%;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 1rem;
    overflow: hidden;
  }

  .select-space {
    width: 100%;
    position: absolute;
    top: calc(100% + 1px);
    left: 0;
    background: white;
    display: none;
    border: 1px solid lightgray;
    border-top: 0;
    &--open {
      display: block;
    }
    .container-list {
      display: flex;
      flex-direction: column;
      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 3rem;
        padding: 0 1rem;
        & > span {
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .values {
          display: flex;
          align-items: center;
          span {
            width: 30px;
            font-size: 20px;
            text-align: center;
          }
          button {
            background: none;
            position: relative;
            border: 1px solid lightgray;
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            font-size: 18px;
            outline: none;
          }
          .left {
            &::after {
              content: '-';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &:hover {
              background-color: #e0e0e0;
            }      
            &:focus {
              outline: 2px solid #000000;
              outline-offset: 2px;
              background-color: #e7f1ff;
            }
          }
          .right {
            &::after {
              content: '+';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &:hover {
              background-color: #e0e0e0;
            }      
            &:focus {
              outline: 2px solid #000000;
              outline-offset: 2px;
              background-color: #e7f1ff;
            }
          }
        }
      }
    }
    .container-buttons {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      font-weight: bold;
      font-size: 1.5rem;

      button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        width: 30px;
        height: 30px;
        &:hover {
          background-color: #e0e0e0;
        }  
        &:focus {
          outline: 2px solid #000000;
          outline-offset: 2px;
          background-color: #e7f1ff;
        }
      }
    }
  }
}

.single-select-passenger {
  border: 1px solid #CFCFCF;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  height: 2.5rem;
  margin: .5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input-view {
    padding: 0 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .values {
      display: flex;
      align-items: center;
      span {
        width: 30px;
        font-size: 20px;
        text-align: center;
      }
      button {
        background: none;
        position: relative;
        border: 1px solid lightgray;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        font-size: 18px;
        outline: none;
        margin-left: .5rem
      }

      .left {
        &::after {
          content: '-';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          background-color: #e0e0e0;
        }
  
        &:focus {
          outline: 2px solid #000000;
          outline-offset: 2px;
          background-color: #e7f1ff;
        }
      }
      .right {
        &::after {
          content: '+';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:hover {
          background-color: #000000;
        }
  
        &:focus {
          outline: 2px solid #000000;
          outline-offset: 2px;
          background-color: #e7f1ff;
        }
      }
    }
  }
}