.CalendarDay__selected {
  background: $gray-light;
  border-color: $gray-light;
}

.CalendarDay__selected:hover {
  background: $gray-light;
  border-color: $gray-light;
}

.CalendarDay__selected:active {
  background: $gray-light;
  border-color: $gray-light;
}

.CalendarDay__select {
  border: 1px solid #e4e7e7;
  color: #484848;
  font-weight: 700;
  line-height: 27px;
  font-size: 18px;
  border-radius: 3px;
  height: 33px;
  margin: -0.3rem 0.1rem 0 0.1rem;
  option {
    font-weight: 500;
    line-height: 27px;
    font-size: 18px;
  }
}

.DayPickerNavigation_leftButton__horizontalDefault {
  top: 22px;
  padding: 8px 8px;
}

.DayPickerNavigation_button__horizontalDefault {
  top: 22px;
  padding: 8px 8px;
}

.container-calendar {
  display: flex;
  position: relative;
  width: 100%;
  i {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-55%);
    z-index: 1;
  }
}

.SingleDatePickerInput__calendar-icon {
  transform: translateY(-2px);
}

.DateRangePicker, .SingleDatePicker {
  width: 100%;
  & > div {
    width: 100%;
  }
  .DateRangePickerInput, .SingleDatePickerInput {
    border: none;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 2.8rem;
    .DateRangePickerInput__arrow {
      width: 10%;
    }
    .DateInput {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      height: 2.6rem;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      border: 0;
      input {
        margin: 0;
        display: flex;
        align-items: center;
        z-index: 1;
        width: 100%;
        height: 2.6rem;
        font-size: 14px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-sizing: border-box;
        padding-left: 2.5rem;
        &::-webkit-input-placeholder {
          font-size: 1rem;
          font-family: sans-serif;
          opacity: .6;
        }
        &:focus {
          outline: 2px solid #000000;
          outline-offset: 2px;
          background-color: #e7f1ff;
          text-decoration: none;
        }
      }

      .DateInput__display-text--focused {
        background: $gray-lighter;
      }

      .DateInput__display-text {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        text-overflow: ellipsis;
      }
    }
  }
}

.SingleDatePickerInput__disabled {
  background-color: white;
}

.SingleDatePicker_picker{
  z-index: 2;  
}

.SingleDatePicker .SingleDatePickerInput .DateInput input:disabled {
  font-style: normal;
  background: #d7d7d7;
}

@media screen and (min-height: 1200px) {
  .DayPicker_portal__horizontal {
    -webkit-box-shadow: none;
    box-shadow: none;
    position: absolute;
    left: 50%;
    top: 30%;
  }
}