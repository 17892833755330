.preview-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance : none;
    appearance: textfield;
  }
  .label {
    color: #54585a;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    font-family: "Open Sans", Arial, sans-serif;
    padding: .5rem 0 0 0;
    width: 100%;
    z-index: 0;
  }
  input, select {
    border: 1px solid #CFCFCF;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    padding: .6rem;
    width: 100%;
    margin: .5rem 0 0 0;
    height: 2.6rem;
  }
  .wlf-select {
    height: 2.5em;
    padding: 0;
    text-indent: .5em;
    line-height: 2.5rem;
    margin: .5rem 0 0 0;
  }
  .online {
    display: flex;
    flex-direction: row;
    & > div {
      flex-grow: 1;
    }
  }
  &--pad {
    padding-top: .5rem;
  }
}

.quote-form-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  .name-quote {
    display: flex;
    justify-content: center;
    width: 10rem;
    transform: translate(5%, -50%);
    background: #FFF;
  }
  .titles {
    margin: 0 .5rem 0 .5rem;
    border-bottom: 1px solid black;
    z-index: 1;
    .title-quote {
      font-size: 1.5rem;
    }
  }
  .container-select {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;

    &--agebands {
      flex-wrap: wrap;
      justify-content: flex-start;

      .preview-field.preview-field--responsive {
        width: 40%;
        padding-right: 10px;
      }
    }

    .preview-field--responsive {
      box-sizing: content-box;
      width: 50%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-right: 1rem;
      &--center {
        align-items: center;
      }
      & > input {
        width: 50%;
      }
    }
  }
}

label {
  margin-bottom: 0;
}

.field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: .5rem 0 0 0;
  width: 100%;
  height: 4rem;
  .label {
    display: flex;
    align-items: center;
    padding: 0 .5rem;
    width: 40%;
  }
  input[type="text"] {
    border: 1px solid #CFCFCF;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    padding: .6rem;
  }
}

.field-radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .label-radio {
    color: #54585a;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding: .5rem 0 0 0;
    width: 100%;
  }
}

.container-radio {
  width: 100%;
  padding: .5rem 0;
  .input-radio {
    float: left;
    margin-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    white-space: pre-line;

    &--lonely {
      flex-basis: 80%;
    }

    .label {
      padding-left: .5rem;
    }

    &:last-child {
      margin-right: 0;
    }

    .check-simul {
      cursor: pointer;
      outline: none; // Remove default outline
      transition: box-shadow 0.3s ease, border 0.3s ease; // Smooth transition for focus styles

      &:focus-visible {
        outline: none; // Prevent default outline
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); // Add a subtle shadow
        border: 2px solid #000; // Add a border to indicate focus
      }

      &:focus {
        outline: none; // Ensure outline is not shown on focus (for mouse clicks)
      }
    }
  }

  &--sub {
    padding: .5rem 2rem;
  }
}


.wlf-button {
  width: 100%;
  margin: 1rem 0;
  @include wlf-button-style("next");
  &:hover {
    color: white;
    text-decoration: none;
  }
  &:focus {
    outline: 2px solid #000000;
    outline-offset: 2px;
    background-color: #e7f1ff;
    text-decoration: none;
  }
  &:visited {
    color: white;
    text-decoration: none;
  }
  &:active {
    color: white;
    text-decoration: none;
  }
  &:link {
    color: white;
    text-decoration: none;
  }
}

.container-checkbox {
  max-height: 500px;
  overflow-y: scroll;
}

.text-error {
  border-top: solid #b21d1d;
  color: #b21d1d;
  padding-top: 3px;
  margin-top: 3px;
  width: 100%;
}

.check-simul {
  position: relative;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #CFCFCF;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  margin: .5rem .5rem .5rem 0;
  &--selected {
    &::after {
      content: '';
      bottom: 0;
      left: 6px;
      position: absolute;
      width: 20px;
      height: 10px;
      transform-origin: 0 100%;
      transform: rotate(-45deg);
      border-left: 2px solid #444;
      border-bottom: 2px solid #444;
    }
  }
}
