@media print {
  .header-site,
  .steps-container,
  .confirmation-block.terminate,
  .summary-container::before,
  .footer {
    display: none !important;
  }

  .flex-display,
  .booking-path-container,
  .summary-container {
    display: block !important;
    width: 100% !important;
    margin: 0 !important;
    max-width: none !important;
    border: none !important;
  }

  .summary-container .summary-title {
    margin: 0 !important;
  }

  .booking-path-content {
    padding: 0 0 0 1rem !important;
  }

  .summary-footer {
    text-align: left !important;
    background: #fff !important;
    color: #000 !important;
  }
}