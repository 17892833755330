.booking-path-container {
  &.full {
    flex-grow: 1;
  }

  h3 {
    margin-top: 40px;
  }

  hr {
    margin: 0 3.5rem 2rem 3.5rem;
  }

  &.up {
    margin: 40px auto 0 0;
  }

  margin: 7rem 0 10px 2rem;
  background: white;
  border-top: 4px solid #0066cc;
  width: 60%;

  .booking-path-content {
    position: relative;
    padding: 1.5rem 3.5rem;


    h3 {
      font-weight: bold;
      font-size: 1.7rem;
    }

    &:focus {
      outline: none;
    }

    .header {
      span {
        font-size: 2.1rem;
        font-weight: 600;
      }

      padding-bottom: 10px;
      border-bottom: 1px solid #eeefee;
      margin-bottom: 20px;
      margin-top: 20px;

      &:focus {
        outline: none;
      }
    
      // Add custom focus styles for keyboard users
      &.keyboard-focus {
        outline: 2px solid #000; // Add a custom outline color for focus state
        outline-offset: 2px; // Optional, for better visibility
      }
    }

    .offer-container {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #FFF;
      font-size: 20px;
      font-weight: 600;
      padding: 10px 20px;
      border-radius: 5px 5px 0 0;

      .right-container {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;

        .price-container {
          display: flex;
          flex-direction: column;
          padding-right: 1.5rem;

          .price,
          .ipt-tax {
            text-align: right;
          }

          .price {
            font-size: 25px;
          }

          .ipt-tax {
            color: #CCCCCC;
            font-size: 14px;
          }
        }

        .button {
          position: relative;
          background: #FDFDFD;
          border: 0;
          border-radius: 3px;
          padding: .5rem 2rem .5rem .5rem;
          color: #54585a;
          min-height: 2rem;
          min-width: 3rem;

          &::after {
            position: absolute;
            top: 50%;
            right: 13px;
            content: '';
            width: 10px;
            height: 10px;
            border-bottom: 2px solid #54585A;
            border-right: 2px solid #54585A;
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }
    .contain-highlight {
      p {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      .bloc-announce {
        font-size: 18px;
        font-weight: 600;
        width: 100%;
        color: red;
        background: #ff0;
        padding: 0.5rem 20px;
      }

      .offer-container {
        border-radius: 0;
      }
    }

    .details-container {
      font-size: 15px;
      cursor: pointer;
      margin-bottom: 1rem;

      .details {
        background: #FFF;
        color: #545555;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .name .fa-info-circle {
          margin-left: 5px;
        }

        .content {
          padding-left: 10px;

          p {
            margin: 0;
            text-align: right;
          }
        }
      }

      .show-details,
      .details {
        padding: 0 20px;
        line-height: 30px;

      }
    
      .show-details {
        .fa {
          line-height: 30px;
          margin-right: 10px;
          font-size: 22px;
        }
      }

      .show-promo-error {
        padding: 0 20px;
        line-height: 30px;
        text-align: right;
      }  

      .open {
        box-sizing: border-box;        
        transition: all .5s ease-out 0s;
        overflow-y: hidden;
      }

      .closed {
        max-height: 0;
        transition: max-height .5s ease-in;
        overflow-y: hidden;
      }

      .terms-and-conditions {
        padding: 5px 20px;
        color: #fff;
        cursor: auto;

        a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }

  .booking-path-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 3.5rem 0 3.5rem;
    margin: 0 0 1rem 0;

    .button {

      &:hover {
        color: white;
      }

      &.left {
        @include wlf-button-style("back");
        background: #6c757d;
      }

      &.right {
        @include wlf-button-style("next");
        background: #0066CC;
      }

      &:hover {
        color: white;
        text-decoration: none;
      }
      &:focus {
        outline: 2px solid #000000;
        outline-offset: 2px;
        background-color: #6c757d;
        text-decoration: none;
      }
    }
  }
}

.steps-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding-left: 20%;
  padding-right: 20%;
  @media (max-width: 67rem) {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;

  .step-square {
    margin-top: -20px;
    width: 40px;
    min-height: 40px;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    line-height: 34px;
    color: white;
    border-radius: 5px;
    border: 4px solid white;
    background: white;
  }

  .step-title {
    line-height: 1rem;
    height: 2rem;
    text-align: center;
    font-size: 14px;
    width: 10rem;
    font-weight: 600;
    padding-top: 7px;
  }

}

.summary-container {
  width: 320px;
  margin: 110px 0 0 20px;
  background: white;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    @include rectangle(#FFF, calc(100% - 70px), 70px);
    transform: translateY(-100%);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    @include arrow(bottomLeft, #FFF, 35px);
    transform: translateY(-100%);
  }

  .summary-title {
    color: #0066cc;
    padding-left: 1rem;
    text-transform: uppercase;
    -webkit-hyphens: auto;
    hyphens: auto;
    font-size: 1.7rem;
    font-weight: 600;
    position: relative;
    z-index: 1;
    margin-top: -50px;
    padding-right: 30px;
  }

  .summary-body {
    padding: 1rem;
  }

  .summary-footer {
    background: #0066cc;
    color: white;
    padding: 1rem;
    text-align: right;

    div.price {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 35px;
    }

    .ipt-tax {
      color: #CCCCCC;
      font-size: 13px;
    }
  }

}

.field-line {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .preview-field {
    flex-basis: 32%;

    &:not(:last-child) {
      margin-right: 2%;
    }
  }

}

.no-product {
  font-family: "Gotham Medium", Arial, serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #E74C3C;
  border-radius: 3px;
  color: #E74C3C;
  background: #FADBD8;
  padding: 1rem;
}

.iframe-payment {
  width: 100%;
  height: 700px;
  border: none;
}

@media (max-width: 77rem) {
  .summary-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin: 7rem auto 10px auto;
    background: #fff;
  }
  .booking-path-container.up {
    margin: 40px auto 0 auto;
  }
}

@media (max-width: 47rem) {

  .field-line {
    flex-wrap: wrap;

    .preview-field {
      flex-basis: 100%;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
  .step-container {
    .step-title {
      text-align: center;
      padding: .5rem;
      box-sizing: border-box;
      width: 5rem;
      word-break: break-all;
    }
  }

  .summary-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin: 7rem 0 10px 0;
    background: #fff;
  }

  .homeSite .body-site .body-content .quote-form {
    width: 100%;
  }

  .booking-path-container.up.full {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin: 40px 0 0 0;
    background: #fff;
    padding: 0 20px;

    .steps-container {
      padding-left: 5%;
      padding-right: 5%;
    }

    .booking-path-content {
      padding: 2rem 0.5rem 2rem 0.5rem;

      .details-container {
        .details {
          flex-direction: column;

          div + div {
            font-size: smaller;
          }
        }
      }
    }

    .booking-path-footer {
      padding: 0;
    }
  }

  .offer-container {
    flex-direction: column;
    .right-container {
      align-self: flex-end;
      padding-top: 1rem;
    }
  }
}
