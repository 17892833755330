.footer {
  color: #76797b;
  width: 100%;

  .footer-content {
    margin: 15px auto;
    color: #000;

    p {
      margin: 0;
    }
  }
}

.bottom-bar {
  width: 100%;
  height: 3rem;
  background: $bottomBarColor;
  min-height: 3rem;
  margin-top: 1rem;
  color: #fff;

  .document-container {
    height: 100%;
    display: flex;
    margin: 0 0 .5rem 0;
    flex: 1;
    justify-content: center;
    align-items: center;

    .document {
      color: #fff;
      padding: 0 2rem;
      cursor: pointer;
      text-decoration: none;
      border-left: 1px solid #eee;

      &:hover {
        text-decoration: underline;
      }

      &:first-child {
        border-left: none;
      }

      &:visited {
        color: #fff;
        text-decoration: none;
      }

      &:active {
        color: #fff;
        text-decoration: none;
      }

      &:link {
        color: #fff;
        text-decoration: none;
      }
    }    
  }
}

@media screen and (max-width: 768px){
  .footer {
    .footer-content {
      cursor: pointer;
    }
  }

  .bottom-bar {
    height: auto;

    .document-container {
      flex-direction: column;
      & > div, a {
        height: 3rem;
        line-height: 3rem;
      }
      .document {        
        border-left: none;
      }
    }
  }


}
