// Media queries breakpoints
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-xs-max: ($screen-sm-min - 1);

$window-padding: 20px;

// Notification variables
$notification-max-width: 360px;
$notification-button-width: 90px;
$notification-close-button: "\f00d";

$notification-success-bg: #ffffff;
$notification-success-border: #4dc657;
$notification-success-icon: "\f058";

$notification-default-bg: #ffffff;
$notification-default-border: #ffffff;

$notification-info-bg: #ffffff;
$notification-info-border: #349ef3;
$notification-info-icon: "\f05a";

$notification-warning-bg: #ffffff;
$notification-warning-border: #f5aa0a;
$notification-warning-icon: "\f071";

$notification-error-bg: #ffffff;
$notification-error-border: #f5311d;
$notification-error-icon: "\f057";

$notification-loading-bg: #ffffff;
$notification-loading-border: #349ef3;
$notification-loading-icon: "\f110";
