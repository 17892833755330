.number-picker-input {
  cursor: pointer;
  z-index: 1;
  box-sizing: border-box;
  outline: none;
  position: relative;
  width: 100%;
  height: 2.5rem;
  border: 1px solid #CFCFCF;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  display: flex;
  justify-content: space-between;
  .number-picker-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
  }
  .first {
    &::after {
      left: calc(100% + 2px);
      position: absolute;
      top: 0;
      transform: translateY(-20%);
      @include dots();
    }
  }
  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0.5rem;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: button;
    border: none;
    box-shadow: none;
    outline: none;
    background: #FFF;
    opacity: 0;
  }
  .container-chevron {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    right: .5rem;
    pointer-events: none;
    color: #999;
    font-size: 11px;
  }
  .simul-text {
    position: absolute;
    top: 50%;
    left: .5rem;
    transform: translateY(-50%);
  }
}

.number-picker-container {
  position: absolute;
  top: calc(100% + 1px);
  display: flex;
  width: 100%;
  justify-content: space-between;
  .fa {
    font-size: 14px;
    position: relative;
    left: 0;
    transform: translateX(-50%);
    color: #999;
  }
}
.number-picker {
  cursor: pointer;
  z-index: 1;
  outline: none;
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);
  align-items: center;
  width: 3rem;
  height: 15rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  .num {
    z-index: 1;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-left: 1px solid #DDD;
    border-right: 1px solid #DDD;
    background: #FFF;
    width: 3rem;
    height: 3rem;
    + .num {
      border-top: 1px solid #DDD;
    }
    &:hover {
      z-index: 2;
      border: none;
      background: #EEE;
      transform-origin: 50% 50%;
    }
  }
}
