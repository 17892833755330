.header-site {
  background-color: #fff;
  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    .icon {
      display: none;
    }
    .document-container {
      margin-right: 2rem;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .document {
        padding: 0.5rem 2rem;
        cursor: pointer;
        border-left: 1px solid #eee;
        font-weight: bold;
        text-transform: uppercase;
        color: #54585a;
        &:first-child {
          border-left: none;
        }
        &:active {
          color: black;
          text-decoration: none;
        }
        &:link {
          color: black;
          text-decoration: none;
        }
        &:hover {
          color: #858585;
          text-decoration: none;
        }
      }
    }
    .image {
      max-height: 90%;
      max-width: 15rem;
      & + .image {
        margin-right: 2rem;
      }
    }
    >a {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

@media screen and (max-width: 77rem) {
  .header-site {
    position: relative;

    &.mr-bt{
      margin-bottom: 3rem;
    }

    .content-header {
      .document-container {
        top: calc(100% + 3rem);
        position: absolute;
        left: 0;
        .document {
          display: none;
          font-weight: 400;
          &:first-child {
            border-left: none;
          }
          &:active {
            color: #fff;
            text-decoration: none;
          }
          &:link {
            color: #fff;
            text-decoration: none;
          }
          &:hover {
            color: #fff;
            text-decoration: none;
          }
        }
        .link-home {
          position: absolute;
          display: flex;
          top: -3rem;
          left: 4rem;
          color: #FFF;
          height: calc(3rem - 1px);
          text-transform: uppercase;
          z-index: 10;
          padding: 0.5rem;
          align-items: center;          
          font-weight: bold;
        }

        &.document-container--responsive {          
          color: #FFF;
          z-index: 100;
          padding: 1rem;
          margin: 0;
          border-top: 1px solid #FFF;
          position: absolute;
          top: calc(100% + 3rem);
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          .document {
            padding: 0.5rem;
            display: flex;
            align-items: center;
            border: none;
            color: #FFF;
            &:link {
              color: #FFF;
              text-decoration: none;
            }
            &:hover {
              color: #FFF;
              font-weight: bold;
            }
            a {
              padding-left: 0;
            }
          }
        }
      }

      .icon {
        z-index: 9;
        background: #616A6B;
        color: #FFF;
        height: 3rem;
        top: 100%;
        left: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        .fa {
          padding-left: 1.5rem;
          font-size: 2rem;
        }
      }
    }
  }
}


@media screen and (max-width: 47rem) {
  .header-site {
    .image {
      max-width: 12rem;
      object-fit: cover;      
    }
    .content-header {
      height: 70px;
      padding-right: 10px;
    }
  }
}

@media screen and (max-width: 26rem) {
  #allianz-logo { 
    display: none;    
  } 
}

