$base-font-size: 14px;

$left-menu-nav-width: 16rem;

/* bootstrap override */
$enable-flex: true;

$brand-primary: #1469b3;

$gray-darker: #2a2d2f;
$gray-dark: #373a3c;
$gray: #55595c;
$gray-light: #818a91;
$gray-lighter: #eceeef;
$gray-lightest: #f7f7f9;

$red-danger: #d9534f;

$nav-tabs-active-link-hover-bg: #ffffff;

$body-bg: $gray-lighter;

$headings-font-weight: 300;

$font-size-h1: 1.6rem;
$font-size-h2: 1.3rem;
$font-size-h3: 1.15rem;
$font-size-h4: 1.1rem;
$font-size-h5: 1.05rem;
$font-size-h6: 1rem;

$backgroundColor: #eceded;
$bottomBarColor: #54585a;

$blueAllianz: #0066ca;

@mixin arrow($direction: down, $color: purple, $size: "10px") {
  display: flex;
  width: 0;
  height: 0;
  border: $size solid $color;
  @if($direction == down) {
    border-color: transparent;
    border-top-color: $color;
  } @else if ($direction == top) {
    border-color: transparent;
    border-bottom-color: $color;
  } @else if ($direction == left) {
    border-color: transparent;
    border-right-color: $color;
  } @else if ($direction == right) {
    border-color: transparent;
    border-left-color: $color;
  } @else if ($direction == topRight) {
    border-left-color: transparent;
    border-bottom-color: transparent;
  } @else if ($direction == topLeft) {
    border-bottom-color: transparent;
    border-right-color: transparent;
  } @else if ($direction == bottomRight) {
    border-top-color: transparent;
    border-left-color: transparent;
  } @else if ($direction == bottomLeft) {
    border-right-color: transparent;
    border-top-color: transparent;
  }
}

@mixin rectangle($color: purple, $width: "10px", $height: "10px") {
  width: $width;
  height: $height;
  background-color: $color;
}

@mixin wlf-button-style($direction: "next") {
  position: relative;
  border: none;
  outline: none;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  color: white;
  @if ($direction == "next") {
    padding: 10px 40px 10px 20px;
    text-align: left;
  } @else if ($direction == "back") {
    padding: 10px 20px 10px 40px;
    text-align: right;
  }
  font-size: 20px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  border-radius: 4px;
    &::after {
      position: absolute;
      content: '';
      width: 15px;
      height: 15px;
      border-bottom:2px solid #FFF;
      border-right: 2px solid #FFF;
      @if ($direction == "next") {
        top: 50%;
        right: 15px;
        transform: translateY(-50%) rotate(-45deg);
      } @else if ($direction == "back") {
        top: 50%;
        left: 15px;
        transform: translateY(-50%) rotate(135deg);
      }
    }
}


@mixin dots($size: 2rem, $color: #999) {
  content: ':';
  font-size: $size;
  font-weight: bold;
  color: $color;
  vertical-align: middle;
  height: 2rem;
}
