.page-loading,
.page-error {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-error {
  color: $red-danger;
}

.base-content {
  margin: 0 auto;
  max-width: 1300px;
  width: 90%;
}
